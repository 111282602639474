import linkedin from "../images/icons8-linkedin-circled.svg"
import email from "../images/icons8-mail.svg"

const socialMediaData = [
    {
        image: linkedin,
        alt: "Linkedin",
        href: "https://www.linkedin.com/in/yuya-hochi-my-profile/"
    },
    {
        image: email,
        alt: "Gmail",
        href: "yuyahochi4blog@gmail.com"
    }
];

export default socialMediaData;